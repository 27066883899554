import React from 'react'
import AniLink from "gatsby-plugin-transition-link/AniLink";
import { graphql } from 'gatsby'
import Header from '../components/header'
import Img from "gatsby-image"
import Footer from "../components/footer"
import SanitizeHTML from "react-sanitized-html"

class Magazine extends React.Component {
  state = {
    audioIsPlaying: false
  }
  start(){
    if(this._audio.paused) {
      this.setState({
        audioIsPlaying: true
      })

      this._audio.play()
    }
    else {
      this.setState({
        audioIsPlaying: false
      })

      this._audio.pause()
    }
  }

  render() {
    const magazine = this.props.data.allMagazines.nodes[0];

    return (
      <main style={{ backgroundColor: '#fff' }}>
        <Header {...this.props} magazine={magazine}/>

        <div className="main-content">
          <div className="container-fluid">
            <h1 className="main">Contents</h1>

            <div className="row">
              {magazine.articles && magazine.articles.map((item, key) => {
                if (!item.magazineContentPageImage)
                  return null;

                return (
                  <div key={key} className="col-md-6">
                    <AniLink cover direction={"left"} bg={item.magazineContentPageImageColour} className="article"
                             to={"/article/" + item.slug}>
                      <div className="row">
                        <div className="col-6">
                          <picture>
                            <img alt={''} src={item.magazineContentPageImage.src}/>
                          </picture>
                        </div>

                        <div className="col-6">
                          <p>{item.titleArtists ? <strong><SanitizeHTML  allowedTags={['span']} html={item.titleArtists}/> </strong> : ''}{item.title}</p>
                        </div>
                      </div>
                    </AniLink>
                  </div>
                )
              })}

              {magazine.isForSale &&
              <div className="col-md-6">
                <a rel="noopener noreferrer" target="_blank" href={magazine.shopURL}
                   className="store-link">Next Level Store</a>
              </div>
              }

              {magazine.hasPDF &&
              <div className="col-md-6">
                <a rel="noopener noreferrer" target="_blank" href={magazine.pdfURL}
                   className="store-link">Download a PDF</a>
              </div>
              }
            </div>

            {magazine.videoURL &&
            <div className='embed-container bottom-image'>
              <iframe src={magazine.videoURL} frameBorder='0' webkitAllowFullScreen mozallowfullscreen allowFullScreen/>
            </div>
            }

            {magazine.audioImage &&
            <div className='audio-image bottom-image'>
              <img src={magazine.audioImage} alt=""/>

              <button onClick={() => {this.start()}} className="play">
                {this.state.audioIsPlaying &&
                  <svg width={250} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 460 460"><g fill="#D8D8D8"><circle cx="230" cy="230" r="230" fillOpacity=".2"/><path d="M123 88h79v284h-79zM268 88h79v284h-79z"/></g></svg>
                }

                {!this.state.audioIsPlaying &&
                  <svg width={250} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 460 460">
                    <g fill="#D8D8D8">
                      <circle fillOpacity=".2" cx="230" cy="230" r="230"/>
                      <path d="M407 230L123 372V88z"/>
                    </g>
                  </svg>
                }
              </button>

              <audio controls src={magazine.audioURL} ref={(a) => this._audio = a}/>
            </div>
            }
          </div>
        </div>

        <Footer {...this.props} />
      </main>
    )
  }
}

export const query = graphql`
  query($slug: String) {
    allMagazines(filter: {slug: {eq: $slug}}) {
      nodes {
        title
        slug
        hasPDF
        pdfURL
        isForSale
        shopURL
        magazineNumber
        videoURL
        audioURL
        audioImage
        articles {
          title
          slug
          titleArtists
          subTitle
          magazineContentPageImageColour
          magazineContentPageImage {
            src
          }
        }
      }
    }
  }
`

export default Magazine